import {
  ISearchRequestPaging,
  ISearchResponseTotals,
  SearchDocumentType,
} from '@wix/client-search-sdk';

/**
 * NOTE: Not sure that this function should be in this module
 */
export function getAbsoluteDocumentIndex(
  paging: ISearchRequestPaging,
  indexOnCurrentPage: number,
): number {
  if (indexOnCurrentPage < 0) {
    return null;
  }
  const { page, pageSize } = paging;
  const numberOfPreviousResults = page > 1 ? (page - 1) * pageSize : 0;
  return numberOfPreviousResults + indexOnCurrentPage;
}

export function getBIDocumentType(
  documentType?: SearchDocumentType,
): SearchDocumentType {
  return documentType || SearchDocumentType.All;
}

export function getBITotals(totals: ISearchResponseTotals): string {
  if (!totals) {
    return;
  }

  return JSON.stringify(totals);
}
