import {
  IPlatformAPI,
  IWixWindowViewMode,
} from '@wix/native-components-infra/dist/src/types/types';

export function getBIDefaults(
  platformAPI: IPlatformAPI,
  viewMode: IWixWindowViewMode,
) {
  return {
    _msid: platformAPI.bi.metaSiteId,
    _visitorId:
      viewMode === 'Site' ? platformAPI.bi.visitorId : platformAPI.bi.ownerId,
    _uuid: platformAPI.bi.ownerId,
  };
}
